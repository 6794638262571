import { Delegate } from 'dom-delegate';

const searchPage = {
  init() {
    this.cacheDom();
    this.bindEvents();
  },
  cacheDom() {
    this.nav = document.getElementsByClassName('js-search-nav')[0];

    if (!this.nav) {
      return;
    }

    this.navItems = Array.from(this.nav.getElementsByTagName('a'));
    this.defaultItem = this.navItems[0];
    this.sections = Array.from(document.getElementsByClassName('js-search-section'));
    this.categorized = document.querySelector('.posts-categorized');
    this.uncategorized = document.querySelector('.posts-uncategorized');
  },
  bindEvents() {
    if (!this.nav || !this.sections) {
      return;
    }

    const delegation = new Delegate(this.nav);
    delegation.on('click', 'a', this.clickHandler.bind(this));
  },
  clickHandler(e) {
    e.preventDefault();

    const el = e.target;
    const id = el.dataset.id;

    this.navItems.forEach((item) => {
      if (item === el) {
        item.classList.add('-active');
        return;
      }

      item.classList.remove('-active');
    });

    this.sections.forEach((i) => {
      const item = i;
      if (item.dataset.id === id) {
        item.style.display = '';
        item.style.borderTop = 'none';
        return;
      }

      if (el === this.defaultItem) {
        this.categorized.classList.add('-hide');
        this.uncategorized.classList.remove('-hide');

        item.style.display = '';
        item.style.borderTop = '';
      } else {
        item.style.display = 'none';

        this.categorized.classList.remove('-hide');
        this.uncategorized.classList.add('-hide');
      }
    });
  },
};

export default searchPage.init();
