import events from './events';

const device = {
  breakpoint: 'desktop',
  init() {
    this.bindEvents();
    this.setBreakpoint();

    return this.breakpoint;
  },

  bindEvents() {
    window.addEventListener('resize', this.setBreakpoint.bind(this));
  },

  setBreakpoint() {
    if (!window.getComputedStyle) return;

    const breakpoint = window.getComputedStyle(document.body, ':after')
      .getPropertyValue('content')
      .replace(/['"]+/g, '');

    if (this.breakpoint !== breakpoint) {
      this.breakpoint = breakpoint;
      events.publish('breakpointChange', this.breakpoint);
    }
  },
};

export default device.init();
