export default {
  events: {},
  subscribe(name, fn) {
    this.events[name] = this.events[name] || [];
    this.events[name].push(fn);
  },
  unsubscribe(name, fn) {
    if (!this.events[name]) return;
    this.events[name] = this.events[name].filter(item => item !== fn);
  },
  publish(name, data) {
    if (!this.events[name]) return;
    this.events[name].forEach(fn => fn(data));
  },
};
