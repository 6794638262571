import axios from 'axios';
import { Delegate } from 'dom-delegate';
import Modal from './modal/modal';
import utils from '../utils';

const login = {
  init() {
    this.content = 'login';
    this.modal = new Modal({
      class: ['-login'],
      header: window.svebio[this.content].header,
      body: window.svebio[this.content].body,
      footer: window.svebio[this.content].footer,
    });

    this.cacheDom();
    this.bindEvents();
  },
  cacheDom() {
    this.invoker = document.getElementsByClassName('js-login')[0];
  },
  bindEvents() {
    this.dd = new Delegate(document.body);
    this.dd.on('click', '.js-login', this.show.bind(this), false);
    this.modal.element.addEventListener('click', this.toggleHandler.bind(this));
    this.form = this.modal.element.getElementsByTagName('form')[0];
    this.form.addEventListener('submit', this.formSubmitHandler.bind(this));
  },
  show(e) {
    e.preventDefault();
    this.modal.show();

    // Wait for animations to finish then set focus
    utils.sleep(200).then(() => {
      this.modal.element.getElementsByTagName('input')[0].focus();
    });

    // Publish event
    utils.events.publish('loginShow');
  },
  toggleHandler(e) {
    if (e.target.classList.contains('js-register')) {
      e.preventDefault();

      this.content = e.target.dataset.content;

      this.modal.set({
        header: window.svebio[this.content].header,
        body: window.svebio[this.content].body,
        footer: window.svebio[this.content].footer,
      })
      .render();

      // Add form event listener
      this.form = this.modal.element.getElementsByTagName('form')[0];
      this.form.addEventListener('submit', this.formSubmitHandler.bind(this));

      // Set focus to first input
      this.modal.element.getElementsByTagName('input')[0].focus();
    }
  },
  responseHandler(form, json) {
    if (json.status) {
      location.reload();
    } else {
      this.setUserResponse(json.message);
      this.modal.shake();
      this.button.classList.remove('-loading');
    }
  },
  setUserResponse(str) {
    let validateEl = this.modal.element.getElementsByClassName('form__validation')[0];

    if (!validateEl) {
      validateEl = document.createElement('div');
      validateEl.classList.add('form__validation');
      const form = this.modal.element.getElementsByTagName('form')[0];
      form.appendChild(validateEl);
    }

    validateEl.innerHTML = str;
  },
  formSubmitHandler(e) {
    e.preventDefault();

    const form = e.target;
    this.button = form.getElementsByClassName('button')[0];
    this.button.classList.add('-loading');

    const formData = new FormData(form);
    formData.append('action', this.content);

    axios(form.action, {
      method: form.method,
      data: formData,
    })
    .then(({ data }) => {
      this.responseHandler(form, data);
    })
    .catch((err) => {
      console.error(err);
    });
  },
};

export default login.init();
