import velocity from 'velocity-animate';
import utils from '../../utils';
import disableScroll from '../../utils/disableScroll';

const nav = {
  state: 'closed',
  init() {
    this.cacheDom();
    this.bindEvents();
  },
  cacheDom() {
    this.header = document.getElementsByClassName('js-header')[0];
    this.nav = this.header.getElementsByClassName('js-nav-responsive')[0];
    this.navInner = this.nav.getElementsByClassName('js-nav-height')[0];
    this.invoker = this.header.getElementsByClassName('js-nav-toggle')[0];
    this.icon = this.invoker.firstElementChild;
    this.getNavHeight();
  },
  bindEvents() {
    this.invoker.addEventListener('click', this.clickHandler.bind(this), false);
  },
  clickHandler(e) {
    e.preventDefault();
    this.navToggle();
  },
  navToggle() {
    if (this.state === 'closed') {
      this.navOpen();
    } else {
      this.navClose();
    }
  },
  getNavHeight() {
    this.windowHeight = window.innerHeight - 61;
  },
  navOpen() {
    this.state = 'open';
    this.icon.classList.add('-open');
    this.getNavHeight();
    disableScroll.lock();

    // Nav animation
    velocity(this.nav, 'stop');
    velocity(this.nav, {
      height: [this.windowHeight, 0],
    }, {
      duration: 300,
      easing: utils.easings.appleEase,
      complete: () => {
        this.nav.style.height = '';
        this.nav.classList.add('-open');
      },
    });

    // Nav inner animation
    velocity(this.navInner, 'stop');
    velocity(this.navInner, {
      translateY: [0, -30],
      opacity: [1, 0],
    }, {
      duration: 400,
      easing: utils.easings.appleEase,
    });
  },
  navClose() {
    this.state = 'closed';
    this.icon.classList.remove('-open');

    disableScroll.release();

    // Nav animation
    velocity(this.nav, 'stop');
    velocity(this.nav, 'reverse', {
      begin: () => {
        this.nav.style.height = this.windowHeight;
        this.nav.classList.remove('-open');
      },
    });

    // Nav inner animation
    velocity(this.navInner, 'stop');
    velocity(this.navInner, 'reverse');
  },
};

export default nav.init();
