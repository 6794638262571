import utils from '../utils';

const header = {
  isSticky: false,
  init() {
    this.cacheDom();
    this.touchDom();
    this.bindEvents();
  },
  cacheDom() {
    this.header = document.querySelector('.js-header');
    this.sticky = this.header.querySelector('.js-header-sticky');
    this.navLogo = this.sticky.querySelector('.-logo');
  },
  touchDom() {
    this.headerHeight = this.header.offsetHeight;
    this.navLogoWidth = this.navLogo.offsetWidth;
    this.navHeight = this.sticky.offsetHeight;
    this.stickyOffset = this.headerHeight - this.navHeight;

    this.navLogo.style.width = 0;
    this.navLogo.style.position = 'static';
  },
  bindEvents() {
    this.toggleCached = this.toggleSticky.bind(this);

    if (utils.device === 'desktop') {
      utils.scroll.add(this.toggleCached);
    }

    utils.events.subscribe('breakpointChange', this.deviceUpdate.bind(this));
  },
  deviceUpdate(breakpoint) {
    if (breakpoint === 'desktop') {
      this.navLogo.style.width = '';
      this.navLogo.style.position = '';
      this.touchDom();

      this.toggleCached = this.toggleSticky.bind(this);
      utils.scroll.add(this.toggleCached);
    } else {
      this.touchDom();
      this.unsetSticky();
      utils.scroll.remove(this.toggleCached);
    }
  },
  toggleSticky(offset) {
    if (offset > this.stickyOffset) {
      if (!this.isSticky) {
        this.setSticky(true);
      }
    } else if (this.isSticky) {
      this.unsetSticky(false);
    }
  },
  setSticky() {
    this.isSticky = true;
    this.header.style.height = `${this.headerHeight}px`;
    this.sticky.classList.add('-sticky');

    this.navLogo.style.width = `${this.navLogoWidth}px`;
    this.navLogo.classList.add('-visible');
  },
  unsetSticky() {
    this.isSticky = false;
    this.sticky.classList.remove('-sticky');
    this.header.style.height = '';

    this.navLogo.style.width = 0;
    this.navLogo.classList.remove('-visible');
  },
};

export default header.init();
