import velocity from 'velocity-animate';
import utils from '../utils';

class CookieNotice {
  constructor() {
    if (!utils.cookie.get('cookie')) {
      this.cacheDom();
      this.show();
    }
  }

  cacheDom() {
    this.element = document.getElementById('cookieNotice');
    this.button = this.element.getElementsByTagName('button')[0];
  }

  bindEvents() {
    this.button.addEventListener('click', (e) => {
      e.preventDefault();
      this.hide();
    }, false);
  }

  show() {
    velocity(this.element, {
      opacity: [1, 0],
      translateX: [0, '50%'],
      scaleX: [1, 0.9],
      scaleY: [1, 0.9],
    }, {
      duration: 200,
      easing: utils.easings.appleEase,
      begin: () => {
        this.element.classList.add('-visible');
      },
      complete: () => {
        this.bindEvents();
      },
    });
  }

  hide() {
    velocity(this.element, 'reverse', {
      complete: () => {
        this.element.classList.remove('-visible');
        utils.cookie.set('cookie', 1, 365 * 5);
      },
    });
  }
}

export default new CookieNotice();
