import { Delegate } from 'dom-delegate';

const members = {
  init() {
    if (!document.querySelector('.js-nav-members')) return;

    this.cacheDom();
    this.bindEvents();
    this.checkLetters();
    this.checkIsExpandable();
  },

  cacheDom() {
    this.nav = document.querySelector('.js-nav-members');
    this.navItems = [...this.nav.getElementsByTagName('button')];
    this.items = [...document.getElementsByClassName('js-member')];
  },

  bindEvents() {
    // Nav delegate
    const navDelegate = new Delegate(this.nav);
    navDelegate.on('click', 'button', (event) => {
      event.preventDefault();
      const el = event.target;

      // Remove current active el
      this.navItems
        .find(item => item.classList.contains('-active'))
        .classList.remove('-active');

      // Set new active el
      el.classList.add('-active');

      // Filter members
      this.filterItems(el.dataset.letter);
    });

    // Document delegate
    const docDelegate = new Delegate(document.querySelector('.main'));
    docDelegate.on('click', '.js-card-expandable', (event) => {
      const el = this.findTargetElement(event.target, 'js-card-expandable');

      el.classList.add('-open');
    });
  },

  findTargetElement(node, str) {
    if (node.classList.contains(str)) {
      return node;
    }

    return this.findTargetElement(node.parentElement, str);
  },

  checkIsExpandable() {
    this.items.forEach((item) => {
      const expandable = item.querySelector('.js-card-expandable');

      if (expandable.scrollHeight < 75) {
        expandable.classList.add('-open');
      }
    });
  },

  checkLetters() {
    const navItems = [...this.navItems];
    navItems.shift();

    const letters = this.items.map(item => item.dataset.letter);

    navItems.forEach((item) => {
      const letter = item.dataset.letter;
      const index = letters.indexOf(letter);

      if (index < 0) {
        item.classList.add('-inactive');
      }
    });
  },

  filterItems(letter) {
    const visibleItems = [...document.querySelectorAll(`[data-letter="${letter}"]`)];

    if (letter === '') {
      this.items.forEach((i) => {
        const item = i;
        item.style.display = '';
      });
    } else {
      this.items.forEach((i) => {
        const item = i;
        item.style.display = 'none';
      });

      visibleItems.forEach((i) => {
        const item = i;
        item.style.display = '';
      });
    }
  },
};

export default members.init();
