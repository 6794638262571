const popup = {
  init(options) {
    this.setOptions(options);
    this.getOffset();
    this.getDimensions();
    this.setPosition();
    this.open();
  },
  setOptions(options) {
    this.options = Object.assign({
      url: '',
      title: 'Window',
      height: 400,
      width: 400,
    }, options);
  },
  getOffset() {
    this.offsetLeft = window.screenLeft === undefined ?
      screen.left :
      window.screenLeft;

    this.offsetTop = window.screenTop === undefined ?
      screen.top :
      window.screenTop;
  },
  getDimensions() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  },
  setPosition() {
    this.left = ((this.windowWidth / 2) - (this.options.width / 2)) + this.offsetLeft;
    this.top = ((this.windowHeight / 2) - (this.options.height / 2)) + this.offsetTop;
  },
  open() {
    const params = `
      width=${this.options.width},
      height=${this.options.height},
      top=${this.top},
      left=${this.left}`;

    this.window = window.open(this.options.url, this.options.title, params);

    if (window.focus) {
      this.window.focus();
    }
  },
};

export default popup;
