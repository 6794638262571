const disableScroll = {
  scrollTop: null,

  resizeListener() {
    document.body.style.height = `${window.innerHeight}px`;
    document.body.style.width = `${window.innerWidth}px`;
  },

  lock() {
    this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    document.body.style.position = 'fixed';
    document.body.style.top = `-${this.scrollTop}px`;
    document.body.style.height = `${window.innerHeight}px`;
    document.body.style.width = `${window.innerWidth}px`;
    document.body.style.overflow = 'hidden';

    window.addEventListener('resize', this.resizeListener);
  },

  release() {
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.height = '';
    document.body.style.width = '';
    document.body.style.overflow = '';

    document.documentElement.scrollTop = this.scrollTop;
    document.body.scrollTop = this.scrollTop;

    window.removeEventListener('resize', this.resizeListener);
  },
};

export default disableScroll;
