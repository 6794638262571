import popup from './popup';

const share = {
  init() {
    this.cacheDom();
    this.bindEvents();
  },
  cacheDom() {
    this.elements = Array.from(document.getElementsByClassName('js-share-button'));
  },
  bindEvents() {
    this.elements.forEach((elm) => {
      elm.addEventListener('click', this.eventHandler.bind(this));
    });
  },
  getElement(node, str) {
    if (node.classList.contains(str)) {
      return node;
    }

    return this.getElement(node.parentElement, str);
  },
  eventHandler(e) {
    e.preventDefault();
    const el = this.getElement(e.target, 'js-share-button');

    popup.init({
      url: el.href,
      title: 'Dela',
      height: 560,
      width: 560,
    });
  },
};

export default share.init();
