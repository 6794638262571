import Mustache from 'mustache';
import template from './favoritesMobile.html';
import device from '../../utils/device';
import events from '../../utils/events';

class TopicsMobile {
  init() {
    this.cacheDom();
    this.bindEvents();

    this.breakpointHandler(device);
  }

  cacheDom() {
    this.topics = document.querySelector('.js-topics-mobile');
    this.topicsList = document.querySelector('.js-topic-list');
    this.topicsListMobile = document.querySelector('.js-topic-list-mobile');
    this.desktopParent = this.topicsList.parentElement;
  }

  bindEvents() {
    events.subscribe('topicsRender', ({ items }) => {
      if (items.length) {
        this.topicsListMobile.innerHTML = Mustache.render(template, items);
      }
    });

    events.subscribe('breakpointChange', (breakpoint) => {
      this.breakpointHandler(breakpoint);
    });
  }

  breakpointHandler(breakpoint) {
    if (breakpoint !== 'desktop' && !this.isMobile) {
      this.isMobile = true;
      this.topics.appendChild(this.topicsList);
    } else if (breakpoint === 'desktop' && this.isMobile) {
      this.isMobile = false;
      this.desktopParent.insertBefore(this.topicsList, this.desktopParent.firstChild);
    }
  }
}

export default new TopicsMobile();
