import Mustache from 'mustache';
import axios, { CancelToken } from 'axios';
import utils from '../utils';
import articleLargeTemplate from '../templates/articleLarge.html';
import articleTinyTemplate from '../templates/articleTiny.html';

const topicsList = {
  init() {
    if (!document.querySelector('.js-topics-page-featured')) return;

    this.cacheDom();
    this.bindEvents();
    this.categories = this.getCategories();
    this.cancel = {
      posts: undefined,
      popular: undefined,
    };
    this.isFetching = {
      posts: false,
      popular: false,
    };
  },
  cacheDom() {
    this.postsContainer = document.querySelector('.js-topics-page-featured');
    this.popularContainer = document.querySelector('.js-topics-page-popular');
  },
  bindEvents() {
    utils.events.subscribe('topicsUpdate', (data) => {
      if (window.svebio.categoryId) return;
      this.categories = data.topics.map(favorite => favorite.id).join(',');

      if (this.postsContainer) this.fetchPosts();
      if (this.popularContainer) this.fetchPopularPosts();
    });
  },
  renderPopularPosts() {
    const data = {
      icons: window.svebio.assets.icons,
      posts: this.popularPosts,
    };
    this.popularContainer.innerHTML = Mustache.render(articleTinyTemplate, data);
  },
  fetchPopularPosts() {
    if (this.isFetching.popular) this.cancel.popular();
    this.isFetching.popular = true;

    this.popularContainer.classList.add('-loading');

    axios(`${window.svebio.paths.ajax}?action=top_posts`, {
      cancelToken: new CancelToken((c) => {
        this.cancel.popular = c;
      }),
    })
    .then(({ data }) => {
      this.popularPosts = data.posts;
      this.renderPopularPosts();
      this.isFetching.popular = false;
      this.popularContainer.classList.remove('-loading');
    })
    .catch((err) => {
      if (!axios.isCancel(err)) {
        console.error(err);
      }
    });
  },
  renderPosts() {
    this.postsContainer.innerHTML = Mustache.render(articleLargeTemplate, {
      icons: window.svebio.assets.icons,
      posts: this.posts,
    });
  },
  fetchPosts() {
    if (this.isFetching.posts) this.cancel.posts();
    this.isFetching.posts = true;

    this.postsContainer.classList.add('-loading');

    const categories = window.svebio.categoryId ? window.svebio.categoryId : this.categories;
    const query = [
      'action=blog_feed',
      'limit=2',
      'offset=0',
      'listing=large',
    ];

    if (categories) {
      query.push(`categories=${categories}`);
    }

    axios(`${window.svebio.paths.ajax}?${query.join('&')}`, {
      cancelToken: new CancelToken((c) => {
        this.cancel.posts = c;
      }),
    })
    .then(({ data }) => {
      this.posts = data.posts;
      this.renderPosts();
      this.isFetching.posts = false;
      this.postsContainer.classList.remove('-loading');
    })
    .catch((err) => {
      if (!axios.isCancel(err)) {
        console.error(err);
      }
    });
  },
  getCategories() {
    const cookieRaw = utils.cookie.get('favorites');
    const cookieContent = cookieRaw ? JSON.parse(decodeURIComponent(cookieRaw)) : false;

    if (cookieContent && cookieContent.length) {
      return cookieContent.map(favorite => favorite.id).join(',');
    }

    return undefined;
  },
};

export default topicsList.init();
