import utils from '../utils';

const headerSearch = {
  isOpen: false,
  isClosing: false,
  init() {
    this.cacheDom();
    this.bindEvents();
  },
  cacheDom() {
    this.header = document.getElementsByClassName('js-header')[0];
    this.invoker = this.header.getElementsByClassName('js-header-search-toggle')[0];
    this.search = this.header.getElementsByClassName('js-header-search')[0];
    this.input = this.search.getElementsByTagName('input')[0];
  },
  bindEvents() {
    this.invoker.addEventListener('click', this.toggle.bind(this), false);
    this.input.addEventListener('blur', this.blur.bind(this), false);
  },
  toggle(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  },
  open() {
    if (this.isClosing) {
      return;
    }

    this.isOpen = true;
    this.search.classList.add('-open');
    this.invoker.classList.add('-active');

    utils.sleep(200).then(() => {
      this.input.focus();
    });

    // Publish event
    utils.events.publish('headerSearchOpen');
  },
  close() {
    this.isOpen = false;
    this.search.classList.remove('-open');
    this.invoker.classList.remove('-active');
    this.input.blur();
  },
  blur() {
    this.isClosing = true;
    this.close();

    utils.sleep(200).then(() => {
      this.isClosing = false;
    });
  },
};

export default headerSearch.init();
