import axios from 'axios';
import events from '../utils/events';

class Register {
  constructor() {
    this.form = document.querySelector('.js-nav-register');

    if (!this.form) return;

    this.cacheDom();
    this.bindEvents();
  }

  cacheDom() {
    this.button = this.form.querySelector('button');
    this.feedback = this.form.querySelector('.js-nav-register-feedback');
  }

  bindEvents() {
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
      this.submit();
    });
  }

  responseHandler({ status, message }) {
    if (status) {
      location.reload();
    } else {
      this.feedback.textContent = message;
      this.button.classList.remove('-loading');
    }
  }

  submit() {
    const formData = new FormData(this.form);
    formData.append('action', 'register');
    this.button.classList.add('-loading');

    // Publish event
    events.publish('topicsRegisterSubmit');

    // Make request
    axios(this.form.action, {
      method: this.form.method,
      data: formData,
    })
    .then(({ data }) => {
      this.responseHandler(data);
    })
    .catch((err) => {
      console.error(err);
    });
  }
}

export default new Register();
