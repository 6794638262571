import Flatpickr from 'flatpickr';
import { Swedish } from 'flatpickr/dist/l10n/sv';

function datePicker() {
  const inputs = [...document.getElementsByClassName('js-flatpickr')];
  if (!inputs.length) return;
  const locale = window.svebio.locale.split('_').shift();

  inputs.forEach((input) => {
    const picker = new Flatpickr(input, {
      locale: locale === 'sv' ? Swedish : null,
    });

    if (picker.isMobile) {
      picker.setDate(new Date());
    }
  });
}

export default datePicker();
