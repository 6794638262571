import Mustache from 'mustache';
import axios, { CancelToken } from 'axios';
import cookie from '../utils/cookie';
import events from '../utils/events';
import articleTemplate from '../templates/articleMedium.html';

class LoadMore {
  constructor() {
    if (!document.querySelector('.js-post-listing-invoker')) {
      return;
    }

    this.cacheDom();
    this.bindEvents();
    this.categories = LoadMore.getCategories();
    this.fetchPostCount();
    this.template = articleTemplate;
    this.defaultOffset = Number(this.invoker.dataset.offset);
    this.offset = this.defaultOffset;
    this.limit = Number(this.invoker.dataset.limit);
    this.cancel = {
      posts: undefined,
    };
    this.isFetching = {
      posts: false,
    };
  }

  cacheDom() {
    this.invoker = document.querySelector('.js-post-listing-invoker');
    this.container = document.querySelector('.js-pagination-content');
  }

  bindEvents() {
    this.invoker.addEventListener('click', (e) => {
      e.preventDefault();
      this.fetchPosts();

      // Publish event
      events.publish('loadMore');
    }, false);

    events.subscribe('topicsUpdate', (data) => {
      this.categories = data.topics.map(favorite => favorite.id).join(',');
      this.offset = this.defaultOffset;

      this.fetchPostsReset();
    });
  }

  static getCategories() {
    const cookieRaw = cookie.get('favorites');
    const cookieContent = cookieRaw ? JSON.parse(decodeURIComponent(cookieRaw)) : false;

    if (cookieContent && cookieContent.length) {
      return cookieContent.map(favorite => favorite.id).join(',');
    }

    return undefined;
  }

  updateOffset() {
    this.offset += this.limit;
  }

  fetchPostCount() {
    this.invoker.classList.remove('-visible');

    // Get query action
    const categories = window.svebio.categoryId ? window.svebio.categoryId : this.categories;
    const query = [
      'action=feed_post_count',
    ];

    if (categories) {
      query.push(`categories=${categories}`);
    }

    // Fetch posts count
    axios(`${window.svebio.paths.ajax}?${query.join('&')}`)
    .then(({ data }) => {
      if (data.count > this.offset) {
        this.invoker.classList.add('-visible');
      }
    })
    .catch((err) => {
      console.error(err);
    });
  }

  fetchPostsReset() {
    if (this.isFetching.posts) this.cancel.posts();
    this.isFetching.posts = true;

    this.container.classList.add('-loading');

    const categories = window.svebio.categoryId ? window.svebio.categoryId : this.categories;
    const query = [
      'action=blog_feed',
      `limit=${this.limit}`,
      'offset=2',
      'listing=small',
    ];

    if (categories) {
      query.push(`categories=${categories}`);
    }

    axios(`${window.svebio.paths.ajax}?${query.join('&')}`, {
      cancelToken: new CancelToken((c) => {
        this.cancel.posts = c;
      }),
    })
    .then(({ data }) => {
      const insert = (arr, index, item) => [
        ...arr.slice(0, index),
        item,
        ...arr.slice(index),
      ];

      this.fetchPostCount();
      this.renderPostsReset({
        posts: insert(data.posts, 2, { html: window.svebio.articleListingAd }),
        icons: window.svebio.assets.icons,
      });

      this.container.classList.remove('-loading');
      this.isFetching.posts = false;
    })
    .catch((err) => {
      if (!axios.isCancel(err)) {
        console.error(err);
      }
    });
  }

  fetchPosts() {
    // Get query action
    const categories = window.svebio.categoryId ? window.svebio.categoryId : this.categories;
    const query = [
      'action=blog_feed',
      `limit=${this.limit}`,
      `offset=${this.offset}`,
      'listing=small',
      'show_sponsored=false',
    ];

    if (categories) {
      query.push(`categories=${categories}`);
    }

    // Start loader
    this.invoker.classList.add('-loading');

    // Fetch posts
    axios(`${window.svebio.paths.ajax}?${query.join('&')}`)
    .then(({ data }) => {
      this.invoker.classList.remove('-loading');
      this.renderPosts({
        posts: data.posts,
        icons: window.svebio.assets.icons,
      });
      this.updateOffset();
      this.fetchPostCount();
    })
    .catch((err) => {
      console.error(err);
    });
  }

  renderPostsReset(data) {
    const html = Mustache.render(this.template, data);
    this.container.innerHTML = html;
  }

  renderPosts(data) {
    const html = Mustache.render(this.template, data);
    this.container.insertAdjacentHTML('beforeend', html);
  }
}

export default new LoadMore();
