const scroll = {
  offset: 0,
  tasks: [],
  length: 0,
  init() {
    this.boundLoop = this.loop.bind(this);
    this.loop();

    return this;
  },
  loop() {
    this.update();
    requestAnimationFrame(this.boundLoop);
  },
  update() {
    const pageYOffset = window.pageYOffset;
    if (this.offset === pageYOffset) return;

    this.offset = pageYOffset;
    for (let i = 0; i < this.length; i += 1) {
      this.tasks[i](this.offset);
    }
  },
  add(fn) {
    this.tasks.push(fn);
    this.length += 1;
    fn(this.offset);
  },
  remove(fn) {
    this.length -= 1;
    this.tasks.splice(this.tasks.indexOf(fn), 1);
  },
};

export default scroll.init();
