import events from '../utils/events';

const newsletter = {
  init() {
    this.cacheDom();
    this.bindEvents();
  },
  cacheDom() {
    this.forms = Array.from(document.getElementsByClassName('js-newsletter-form'));
  },
  bindEvents() {
    this.forms.forEach((form) => {
      form.addEventListener('submit', this.send.bind(this));
    });
  },
  send() {
    // e.preventDefault();

    // Publish event
    events.publish('newsletterSignUp');

    // var form = e.target;
    // var action = form.action;
    // var method = form.method;

    // // Add loader

    // fetch(action, {
    //   method: method,
    //   mode: 'no-cors',
    //   body: new FormData(form)
    // })
    // .then(function(res) {
    //   return res.json();
    // })
    // .then(function(json) {
    //   console.log(json);

    //   // Remove loader
    // })
    // .catch(function(err) {
    //   console.log(err);
    // });
  },
};

export default newsletter.init();
