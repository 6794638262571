import Mustache from 'mustache';
import velocity from 'velocity-animate';
import utils from '../../utils';
import template from './select.html';

function Select(element) {
  this.element = element;
  this.isOpen = false;
  this.icon = `${window.svebio.assets.icons}#angle-down`;
}

Select.prototype.init = function () {
  this.cacheDom();
  this.getData();
  this.render();
  this.bind();
};

Select.prototype.cacheDom = function () {
  this.select = this.element.querySelector('select');
  this.options = this.select.getElementsByTagName('option');
  this.selectHTML = this.select.outerHTML;
};

Select.prototype.getData = function () {
  this.data = {
    label: this.element.dataset.label,
    options: this.getOptions(),
    icon: this.icon,
  };
};

Select.prototype.getOptions = function () {
  // Convert to array
  let options = Array.from(this.options);

  // Convert node to object
  options = options.map((item, index) => ({
    name: item.textContent,
    value: item.value,
    index,
  }));

  return options;
};

Select.prototype.render = function () {
  const html = Mustache.render(template, this.data);
  this.element.innerHTML = html + this.selectHTML;

  this.label = this.element.getElementsByClassName('select__label')[0];
  this.list = this.element.getElementsByClassName('select__list')[0];
  this.labelText = this.label.firstElementChild;
};

Select.prototype.bind = function () {
  this.label.addEventListener('click', this.toggle.bind(this), false);
  this.list.addEventListener('click', this.change.bind(this), false);
};

Select.prototype.toggle = function (e) {
  e.preventDefault();
  e.stopPropagation();

  if (this.isOpen) {
    this.close();
  } else {
    this.open();
  }
};

Select.prototype.open = function () {
  this.isOpen = true;
  this.bodyClickHandlerCached = this.bodyClickHandler.bind(this);
  this.listHeight = 46 * this.data.options.length < 220 ? 46 * this.data.options.length : 220;

  velocity(this.list, 'stop');
  velocity(this.list, {
    opacity: [1, 0],
    height: [this.listHeight, 0],
    translateY: [0, -10],
  }, {
    duration: 200,
    easing: utils.easings.appleEase,
    begin: () => {
      this.element.classList.add('-open');
    },
  });

  document.body.addEventListener('click', this.bodyClickHandlerCached, true);
};

Select.prototype.close = function () {
  this.isOpen = false;

  velocity(this.list, 'stop');
  velocity(this.list, 'reverse', {
    begin: () => {
      this.element.classList.remove('-open');
    },
  });

  document.body.removeEventListener('click', this.bodyClickHandlerCached, true);
};

Select.prototype.change = function (e) {
  e.preventDefault();
  e.stopPropagation();

  if (e.target && e.target.nodeName !== 'LI') {
    return;
  }

  const el = e.target;
  const select = this.element.querySelector('select');

  select.value = el.dataset.value;
  select.selectedIndex = el.dataset.index;
  this.labelText.textContent = e.target.textContent;
  this.element.classList.add('-active');

  const event = new Event('change');
  select.dispatchEvent(event);

  this.close();
};

Select.prototype.bodyClickHandler = function (e) {
  if (!utils.eventPathFindElement(e, this.element)) {
    this.close();
  }
};

const selects = function () {
  const elements = [...document.getElementsByClassName('js-select')];

  elements.forEach((elm) => {
    const select = new Select(elm);
    select.init();
  });
};

export default selects();
