import events from './utils/events';
import device from './utils/device';
import cookie from './utils/cookie';
import scroll from './utils/scroll';

export default {
  events,
  device,
  cookie,
  scroll,
  sleep(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  },
  eventPathFindElement(e, node) {
    const event = e;

    if (!event.path) {
      let el = event.target;
      event.path = [];

      while (el !== document.body) {
        event.path.push(el);
        el = el.parentElement;
      }
    }

    return event.path.find(item => item.classList && item === node);
  },
  easings: {
    swiftOut: [0.55, 0, 0.1, 1],
    appleEase: [0.4, 0.01, 0.165, 0.99],
    appleEaseAlt: [0.28, 0.11, 0.32, 1],
  },
};
