import { Delegate } from 'dom-delegate';
import events from '../utils/events';

function logEvents() {
  // New delegate
  const domListener = new Delegate(document.body);

  // Contact button click
  domListener.on('click', '.js-contact-button', () => {
    ga('send', 'event', {
      eventCategory: 'Contact Module',
      eventAction: 'click',
    });
  });

  // Ad click
  domListener.on('click', '.js-ad', function onAdClick() {
    if (!this.dataset.customer) return;

    ga('send', 'event', {
      eventCategory: 'Ads',
      eventAction: 'click',
      eventLabel: `${this.dataset.customer} - ${this.dataset.campaign} - ${this.dataset.area}`,
    });
  });

  // Article featured click
  domListener.on('click', '.js-featured-article-link', function onFeaturedAricleClick() {
    ga('send', 'event', {
      eventCategory: 'Big News',
      eventAction: 'click',
      eventLabel: this.dataset.name,
      transport: 'beacon',
    });
  });

  // Sponsored article external link
  domListener.on('click', '.js-sponsored-article-external-link', function onSponsoredAricleClickExternal() {
    ga('send', 'event', {
      eventCategory: 'Sponsored article',
      eventAction: 'external link click',
      eventLabel: this.dataset.articleName,
    });
  });

  // Topics dropdown
  events.subscribe('topicsDropdownOpen', () => {
    ga('send', 'event', {
      eventCategory: 'Favorites',
      eventAction: 'open',
      eventLabel: 'open',
    });
  });

  // Topics favorite/unfavorite
  events.subscribe('topicsUpdate', ({ topic, isFavorite }) => {
    const action = isFavorite ? 'favorite' : 'unfavorite';

    ga('send', 'event', {
      eventCategory: 'Favorites',
      eventAction: action,
      eventLabel: topic.trim(),
    });
  });

  // Topcis show more button
  events.subscribe('topicsMoreOpen', () => {
    ga('send', 'event', {
      eventCategory: 'Favorites',
      eventAction: 'show more',
      eventLabel: 'show more',
    });
  });

  // Topcis register submit
  events.subscribe('topicsRegisterSubmit', () => {
    ga('send', 'event', {
      eventCategory: 'Register',
      eventAction: 'submit',
      eventLabel: 'Topics',
    });
  });

  // Articles load more
  events.subscribe('loadMore', () => {
    ga('send', 'event', {
      eventCategory: 'Articles',
      eventAction: 'show more',
    });
  });

  // Newsletter sign up
  events.subscribe('newsletterSignUp', () => {
    ga('send', 'event', {
      eventCategory: 'Newsletter',
      eventAction: 'submit',
    });
  });

  // Login modal open
  events.subscribe('loginShow', () => {
    ga('send', 'event', {
      eventCategory: 'Log In',
      eventAction: 'open',
    });
  });

  // Login
  events.subscribe('loginSubmit', () => {
    ga('send', 'event', {
      eventCategory: 'Log In',
      eventAction: 'submit',
    });
  });

  // Search
  events.subscribe('headerSearchOpen', () => {
    ga('send', 'event', {
      eventCategory: 'Search',
      eventAction: 'open',
      eventLabel: 'Header',
    });
  });
}

export default logEvents();
