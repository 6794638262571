import Mustache from 'mustache';
import { Delegate } from 'dom-delegate';
import utils from '../../utils';

const renderFavorites = {
  init() {
    this.bindEvents();
    this.template = document.getElementById('js-favorites-template').innerHTML;
    this.data = {};
  },

  bindEvents() {
    utils.events.subscribe('topicsRender', (data) => {
      this.data = data;
      this.update(this.data);
    });

    window.addEventListener('resize', () => {
      this.update(this.data);
    });

    // TODO: Make this not ugly
    const d = new Delegate(document.body);
    d.on('click', '.js-nav-more', (e) => {
      e.preventDefault();

      const el = e.target.nextElementSibling;
      const close = () => {
        el.classList.remove('-open');

        requestAnimationFrame(() => {
          document.removeEventListener('click', close);
        });
      };

      if (el.classList.contains('-open')) {
        el.classList.remove('-open');
      } else {
        el.classList.add('-open');

        requestAnimationFrame(() => {
          document.addEventListener('click', close);
        });

        utils.events.publish('topicsMoreOpen');
      }
    });
  },

  update({ parent, items }) {
    this.parent = parent;
    this.items = items;
    this.parseItems();
    this.render();
  },

  getNav() {
    return this.parent
      .parentElement
      .parentElement
      .parentElement;
  },

  calculateWidth(i) {
    return (i * 10) + 30;
  },

  parseItems() {
    this.mainItems = [];
    this.secondaryItems = [];
    this.showSecondary = false;
    const maxWidth = this.getNav().offsetWidth - 380;
    let width = 0;

    this.items.forEach((item) => {
      width += this.calculateWidth(item.name.length);

      if (width >= maxWidth) {
        this.showSecondary = true;
        this.secondaryItems.push(item);
        return;
      }

      this.mainItems.push(item);
    });
  },

  render() {
    const html = Mustache.render(this.template, {
      icons: window.svebio.assets.icons,
      main: this.mainItems,
      showSecondary: this.showSecondary,
      secondary: this.secondaryItems,
    });

    this.parent.innerHTML = html;
  },
};

export default renderFavorites;
