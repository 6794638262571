import velocity from 'velocity-animate';
import utils from '../../utils';

const navItemToggle = {
  init() {
    this.cacheDom();
    this.bindEvents();
  },
  cacheDom() {
    this.toggle = Array.from(document.getElementsByClassName('js-nav-item-toggle'));
  },
  bindEvents() {
    this.toggle.forEach((item) => {
      item.addEventListener('click', this.toggleItem.bind(this), false);
    });
  },
  toggleItem(e) {
    const parent = e.target.parentElement;
    const el = parent.getElementsByClassName('js-nav-item-expander')[0];
    const height = parent.getElementsByClassName('js-nav-item-expander-height')[0].offsetHeight;

    if (parent.classList.contains('-open')) {
      el.dataset.state = 'open';
    }

    if (el.dataset.state === 'closed' || el.dataset.state === undefined) {
      el.dataset.state = 'open';
      parent.classList.add('-open');

      velocity(el, 'stop');
      velocity(el, {
        height: [height, 0],
        opacity: [1, 0],
      }, {
        duration: 200,
        easing: utils.easings.appleEase,
        complete: () => {
          el.style.height = '';
        },
      });
    } else {
      el.dataset.state = 'closed';
      parent.classList.remove('-open');

      velocity(el, 'stop');
      velocity(el, {
        height: [0, height],
        opacity: [0, 1],
      }, {
        duration: 200,
        easing: utils.easings.appleEase,
        begin: () => {
          el.style.height = `${height}px`;
        },
      });
    }
  },
};

export default navItemToggle.init();
