import axios from 'axios';
import { Delegate } from 'dom-delegate';
import utils from '../../utils';
import render from './render';
import renderMobile from './topicsMobile';

class Topics {
  constructor() {
    if (!document.querySelector('.js-topic-favorites')) return;

    render.init();
    renderMobile.init();

    this.favorites = [];
    this.favoritesDefault = [];
    this.cacheDom();
    this.getFavorites();
    this.getTopicsDefault();
    this.render();
    this.bindEvents();
  }

  cacheDom() {
    this.favoritesContainer = document.getElementsByClassName('js-topic-favorites')[0];
    this.topicsContainer = document.getElementsByClassName('js-topic-list')[0];
    this.topicsElements = this.topicsContainer.getElementsByTagName('a');
  }

  bindEvents() {
    this.dd = new Delegate(this.topicsContainer);
    this.dd.on('click', '.js-topic-favorite', (e) => {
      const el = e.target.matches('.js-topic-favorite') ? e.target : e.target.parentElement;
      e.preventDefault();
      this.toggleFavorite(Topics.getObj(el.previousElementSibling));
    }, false);
  }

  render() {
    utils.events.publish('topicsRender', {
      parent: this.favoritesContainer,
      items: this.favorites.length ? this.favorites : this.favoritesDefault,
    });
  }

  addFavorite({ id, link, name, active }) {
    this.favorites.push({ id, link, name, active });
    this.sortFavorites();
    this.setFavoritesCookie();
    this.updateTopic(id, true);
    this.render();
  }

  removeFavorite(id) {
    this.favorites.splice(this.getFavoriteIndex(id), 1);
    this.sortFavorites();
    this.setFavoritesCookie();
    this.updateTopic(id, false);
    this.render();
  }

  toggleFavorite(obj) {
    if (this.getFavoriteIndex(obj.id) === -1) {
      this.addFavorite(obj);
    } else {
      this.removeFavorite(obj.id);
    }
  }

  sortFavorites() {
    this.favorites.sort((a, b) => a.name.localeCompare(b.name));
  }

  setFavoritesCookie() {
    const favoritesParsed = this.favorites.map(({ id, name, link }) => ({ id, name, link }));
    const content = encodeURIComponent(JSON.stringify(favoritesParsed, false));
    utils.cookie.set('favorites', content, 365 * 10);
  }

  getFavoriteIndex(prop) {
    return this.favorites.findIndex(item => item.id === prop);
  }

  getFavorites() {
    const topics = Topics.getFavoritesCookieItems();
    const availableTopics = Topics.getTopics();

    if (topics) {
      this.favorites = topics.map(({ id, name, link }) => {
        const item = availableTopics.find(i => i.id === Number(id));
        return {
          id,
          name,
          link,
          active: item.active,
        };
      });
    }
  }

  getTopicsDefault() {
    this.favoritesDefault = window.svebio.topicsDefault.map(item => ({
      id: item.id,
      name: item.name,
      active: item.active,
      link: `${window.svebio.paths.base}/${item.slug}`,
    }));
  }

  updateTopic(id, isFavorite) {
    const el = this.topicsContainer.querySelector(`[data-id="${id}"]`);

    if (isFavorite) {
      el.classList.add('-favorite');
    } else {
      el.classList.remove('-favorite');
    }

    utils.events.publish('topicsUpdate', {
      topics: this.favorites,
      topic: el.textContent,
      isFavorite,
    });

    if (window.svebio.isLoggedIn) {
      // Update user favorites
      axios(`${window.svebio.paths.ajax}?action=save_favorites`)
      .catch((err) => {
        console.error(err);
      });
    }
  }

  static getTopics() {
    return window.svebio.topics.map(item => ({
      id: item.id,
      active: Boolean(item.active),
    }));
  }

  static getFavoritesCookieItems() {
    const cookieRaw = utils.cookie.get('favorites');
    const cookieContent = cookieRaw ? JSON.parse(decodeURIComponent(cookieRaw)) : false;

    if (cookieContent && cookieContent.length) {
      return cookieContent;
    }

    return undefined;
  }

  static getObj(node) {
    return {
      id: node.dataset.id,
      name: node.dataset.name,
      link: node.href,
      active: node.dataset.active === 'true',
    };
  }
}

export default new Topics();
