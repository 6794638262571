import Mustache from 'mustache';
import utils from '../../utils';
import backdrop from '../backdrop';
import disableScroll from '../../utils/disableScroll';
import template from './modal.html';

function Modal(options) {
  this.options = {
    class: [],
    template,
  };
  this.set(options);
  this.create();
  this.render();
  this.bind();
}

Modal.prototype.set = function (options) {
  this.options = Object.assign(this.options, options);
  return this;
};

Modal.prototype.create = function () {
  this.element = document.createElement('div');
  this.element.classList.add('modal');

  this.options.class.forEach((item) => {
    this.element.classList.add(item);
  });

  this.element = document.body.appendChild(this.element);
  this.scrollBarWidth = `${window.innerWidth - document.body.clientWidth}px`;
};

Modal.prototype.render = function () {
  this.element.innerHTML = Mustache.render(this.options.template, this.options);
  return this;
};

Modal.prototype.bind = function () {
  this.element.addEventListener('click', this.backdropHandler.bind(this), false);
  this.element.addEventListener('click', this.closeHandler.bind(this), false);
};

Modal.prototype.show = function () {
  backdrop.show();
  this.element.classList.add('-show');
  this.element.style.paddingRight = this.scrollBarWidth;
  document.body.style.paddingRight = this.scrollBarWidth;
  // scroll.lock(this.element);
  disableScroll.lock();
};

Modal.prototype.hide = function () {
  this.element.style.paddingRight = '';
  document.body.style.paddingRight = '';
  backdrop.hide();
  this.element.classList.remove('-show');
  // scroll.unlock(this.element);
  disableScroll.release();
};

Modal.prototype.shake = function () {
  this.element.classList.add('-shake');
  utils.sleep(600).then(() => {
    this.element.classList.remove('-shake');
  });
};

Modal.prototype.backdropHandler = function (e) {
  if (e.target === this.element) {
    this.hide();
  }
};

Modal.prototype.closeHandler = function (e) {
  if (e.target.classList.contains('modal__close') ||
    e.target.parentElement.classList.contains('modal__close') ||
    e.target.parentElement.parentElement.classList.contains('modal__close')) {
    this.hide();
  }
};

export default Modal;
