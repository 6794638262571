import utils from '../../utils';

const nav = {
  isVisible: false,
  init() {
    if (!document.querySelector('.js-nav-dropdown')) {
      return;
    }

    this.cacheDom();
    this.bindEvents();
  },
  cacheDom() {
    this.invoker = document.getElementsByClassName('js-nav-dropdown-toggle')[0];
    this.line = document.getElementsByClassName('js-nav-dropdown-line')[0];
    this.nav = document.getElementsByClassName('js-nav-dropdown')[0];
  },
  bindEvents() {
    this.invoker.addEventListener('click', this.toggle.bind(this));
  },
  show() {
    this.isVisible = true;
    this.nav.classList.add('-visible');
    this.invoker.classList.add('-active');
    this.line.classList.add('-active');

    this.bodyClickHandlerCached = this.bodyClickHandler.bind(this);
    document.body.addEventListener('click', this.bodyClickHandlerCached, true);
  },
  hide() {
    this.isVisible = false;
    this.nav.classList.remove('-visible');
    this.invoker.classList.remove('-active');
    this.line.classList.remove('-active');

    document.body.removeEventListener('click', this.bodyClickHandlerCached, true);
  },
  toggle(e) {
    e.preventDefault();

    if (this.isVisible) {
      this.hide();
    } else {
      this.show();

      // Publish event
      utils.events.publish('topicsDropdownOpen');
    }
  },
  bodyClickHandler(e) {
    if (utils.eventPathFindElement(e, this.invoker)) {
      return;
    }

    if (!utils.eventPathFindElement(e, this.nav)) {
      this.hide();
    }
  },
};

export default nav.init();
