const backdrop = {
  init() {
    this.create();
    this.render();

    return {
      show: this.show.bind(this),
      hide: this.hide.bind(this),
      toggle: this.toggle.bind(this),
    };
  },
  create() {
    this.element = document.createElement('div');
    this.element.classList.add('backdrop');
  },
  render() {
    this.element = document.body.appendChild(this.element);
  },
  show() {
    this.isVisible = true;
    this.element.classList.add('-show');
    document.documentElement.classList.add('disableScroll');
  },
  hide() {
    this.isVisible = false;
    this.element.classList.remove('-show');
    document.documentElement.classList.remove('disableScroll');
  },
  toggle() {
    if (this.isVisible) {
      this.hide();
    } else {
      this.show();
    }
  },
};

export default backdrop.init();
